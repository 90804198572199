<template>
  <home-header fixed/>
  <header-selete :style="{top:'47px'}" fixed :show-return="false" ref="selectkeyword" placeholder='搜索案例名称'
                 @submit="updateList"/>
  <div class="dao-hang">
    <van-tabs v-model:active="pageName" @change="pageChange">
      <van-tab title="找服务" name="FuWuDaTing">
      </van-tab>
      <van-tab title="找项目" name="XiangMuDaTing"></van-tab>
      <van-tab title="案例" name="AnLiDaTing"></van-tab>
    </van-tabs>
    <div class="shai-xuan">
      <div class="van-ellipsis">
        <select-top-address ref="addDiv" @change="updateList"/>
      </div>
      <div class="van-ellipsis">
        <select-top-list-order ref="listOrder" @change="updateList"/>
      </div>
      <div class="van-ellipsis">
        <select-top-fu-wu-type ref="typeDiv" @change="updateList"/>
      </div>
    </div>
  </div>
  <div class="dao-hang-height"></div>
  <list-page ref="xiaLa" @load="getLoad" top="190px" bottom="80px">
    <div v-for="(vo) in dataList"
         :key="vo.id">
      <an-li-list :to="{name:'FuWuShangInfoDetail',query:{id:vo.id}}" :an-li-info="vo"/>
    </div>
  </list-page>
  <footer-nav fixed/>
</template>

<script>
//项目大厅
import HomeHeader from "../open/homeHeader";
import {setTitle} from "@/api/GongJu";
import _ from "lodash";
import http from "@/api/http";
import HeaderSelete from "@/views/open/headerSelete";
import SelectTopAddress from "@/components/select/selectTopAddress";
import SelectTopListOrder from "@/components/select/selectTopListOrder";
import SelectTopFuWuType from "@/components/select/SelectTopFuWuType";
import FooterNav from "@/views/open/footerNav";
import AnLiList from "@/views/index/public/AnLiList";
import ListPage from "@/components/list/ListPage";
import XEUtils from "xe-utils";

export default {
  name: 'AnLiDaTing',
  components: {
    ListPage,
    AnLiList, FooterNav, SelectTopFuWuType, SelectTopListOrder, SelectTopAddress, HeaderSelete, HomeHeader
  },
  data() {
    return {
      where: {},
      pageName: 'AnLiDaTing',
      dataList: []
    }
  },
  deactivated() {
    this.setNav()
  },
  mounted() {
    this.setNav()
  },
  methods: {
    setNav() {
      setTitle(_.get(this.$route, 'meta.title'))
      this.pageName = 'AnLiDaTing'
    },
    getLoad({num, size}) {
      this.where.address_name = this.$refs.addDiv.getAddressName()
      this.where = {...this.where, ...this.$refs.typeDiv.getWhere()}
      this.where.keyword = this.$refs.selectkeyword.getKeyword()
      this.where.page = num
      this.where.data_length = size
      if (!_.isEmpty(this.$refs.listOrder.getOrder())) {
        this.where.order_field = 'create_time'
        this.where.order_type = this.$refs.listOrder.getOrder()
      } else {
        this.where.order_field = 'order_list'
        this.where.order_type = 'asc'
      }

      http.get('/gzhphp/fu_wu_shang/getAnLi', {params: this.where, load: false}).then(da => {
        if (da.code === 1) {
          if (num === 1) this.dataList = []
          XEUtils.each(da.data.data, n => {
            this.dataList.push(n)
          })
          this.$refs.xiaLa.endSuccess(da.data.data.length)
        }
      })
    },
    pageChange(page) {
      this.$router.push({name: page})
    },
    updateList(item) {
      this.$refs.xiaLa.resetData()
    }
  },
  beforeRouteEnter(to, from, next) { // 如果没有配置顶部按钮或isBounce,则beforeRouteEnter不用写
    next(vm => {
      // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
      vm.$refs.xiaLa && vm.$refs.xiaLa.beforeRouteEnter()
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.xiaLa && this.$refs.xiaLa.beforeRouteLeave()
    next()
  },
}
</script>

<style scoped lang="less">
@import "../../assets/da-ting.less";
</style>


