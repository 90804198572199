<template>
  <div>
    <span @click="setOrder">
      最新发布 <img class="tu-biao" :src="icon[orderType]"/>
    </span>

  </div>
</template>

<script>
//选择排序
export default {
  name: 'selectTopListOrder',
}
</script>
<script setup>
import {ref, defineEmit, useContext} from 'vue';
import {Toast} from 'vant';

const exit = defineEmit(['change'])
const ext = useContext()
let orderType = ref('')
let icon = {
  '': require('../../assets/icon/wu-xian-ze.png'),
  asc: require('../../assets/icon/chao-shang.png'),
  desc: require('../../assets/icon/chao-xia.png'),

}

function setOrder() {
  if (orderType.value === 'desc') orderType.value = 'asc'
  else if (orderType.value === 'asc') orderType.value = ''
  else if (orderType.value === '') orderType.value = 'desc'
  exit('change', orderType.value)
}

ext.expose({
  getOrder() {
    return orderType.value
  }
})
</script>
<style scoped>
.tu-biao {
  width: 8px;
  display: inline;
  margin-top: -2px;
}
</style>
